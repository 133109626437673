import React, { useState } from "react"
import { Link } from "gatsby"
import FormattedDate from "./formatteddate"
import { ArticleConsumer } from "./player-context"

function NewsCard(props) {
  const [hasImage, setHasImage] = useState(props.item.image ? true : false)

  return (
    <>
      <ArticleConsumer>
        {playerContext => (
          <article
            key={props.item.id}
            className={`news-item ${
              hasImage ? "has-media" : ""
            } news-card clearfix`}
          >
            <div className="item-wrapper clearfix">
              <div className="one-line top-content clearfix on-masonry">
                <div className="categories">
                  {props.item.source_countryCode.trim() != "" && (
                    <span className="source-country-flag f16">
                      <i
                        className={`flag ${props.item.source_countryCode.toLowerCase()}`}
                      ></i>
                    </span>
                  )}
                  <span class="news-source">{props.item.sourcename}</span>
                  <span className="news-date floatright">
                    <FormattedDate date={props.item.published}></FormattedDate>
                  </span>
                </div>
              </div>

              <Link to={`/article/${props.item.id}`} className="news-media">
                <img
                  referrerPolicy="no-referrer"
                  onError={i => {
                    i.target.style.display = "none"
                    setHasImage(false)
                  }}
                  alt={props.item.title}
                  src={props.item.image}
                  loading="lazy"
                />
              </Link>
              <div className="news-content">
                <div className="one-line clearfix on-grid">
                  <div className="categories floatright clearfix">
                    {props.item.source_countryCode.trim() != "" && (
                      <span className="source-country-flag f16">
                        <i
                          className={`flag ${props.item.source_countryCode.toLowerCase()}`}
                        ></i>
                      </span>
                    )}
                    <span class="news-source">{props.item.sourcename}</span>
                  </div>{" "}
                  <div className="news-date floatleft">
                    <FormattedDate date={props.item.published}></FormattedDate>
                  </div>
                </div>
                <Link to={`/article/${props.item.id}`} className="news-title">
                  {props.item.title}
                </Link>
                <div className="one-line news-actions clearfix">
                  {process.env.ENABLE_AUDIO && !props.hidePlayButton && (
                    <div className="listen floatright">
                      <button onClick={() => play(playerContext, props.item)}>
                        <span>
                          <i
                            className={
                              playerContext.state &&
                              playerContext.state.id == props.item.id
                                ? "fal fa-stop"
                                : "fal fa-play"
                            }
                          />{" "}
                          {playerContext.state &&
                          playerContext.state.id == props.item.id
                            ? " ايقاف "
                            : " إستمع "}
                        </span>
                      </button>
                    </div>
                  )}
                  <div class="share-and-related floatleft clearfix">
                    <button
                      className="share floatleft"
                      onClick={() => doShare(props.item)}
                    >
                      <i className="fas fa-share" />
                    </button>
                    <Link
                      to={`/article/${props.item.id}`}
                      className="related-news floatleft"
                    >
                      <span>
                        ({props.item.sources_count + 1}) مصدر{" "}
                        {props.item.predicted_category}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                hidden={
                  !(
                    playerContext.state &&
                    playerContext.state.id == props.item.id &&
                    playerContext.playing
                  )
                }
                className="is-playing"
              >
                <div
                  className="play-progress"
                  style={{ width: `${playerContext.played * 100}%` }}
                ></div>
              </div>
            </div>
          </article>
        )}
      </ArticleConsumer>
    </>
  )
}

export function play(playerContext, item) {
  playerContext.setPlayAll(false)
  playerContext.setPlaying(true)
  playerContext.setPlayed(0)
  if (playerContext.state && playerContext.state.id == item.id) {
    playerContext.setCurrentPlaying(null)
  } else {
    playerContext.setPlaying(true)
    playerContext.setCurrentPlaying(item)
  }
}

export function doShare(info) {
  if (info) {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.text = `doShare('${info.title}',"/article/${info.id}")`
    document.body.appendChild(script)
  }
}

export default NewsCard
