import React from "react"
import Layout from "./layout"
import NewsCard from "./newscard"
import SEO from "./seo"

function CatNews(props) {
  return (
    <>
      <SEO title={props.catArabicName}></SEO>
      <Layout>
        <div className="wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <section className="news-list layout-grid">
                  {props.items.map((item, index) => (
                    <NewsCard
                      key={index}
                      item={item}
                      hidePlayButton={props.hidePlayButton}
                    ></NewsCard>
                  ))}
                </section>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default CatNews
