import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation()
  const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    defaultImage,
    twitterUsername,
  } = site.siteMetadata
  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }
  return (
    <Helmet title={seo.title} titleTemplate={titleTemplate}>
      <meta
        data-react-helmet="true"
        name="description"
        content={seo.description}
      />
      <meta data-react-helmet="true" name="image" content={seo.image} />
      {seo.url && (
        <meta data-react-helmet="true" property="og:url" content={seo.url} />
      )}
      {(article ? true : null) && (
        <meta data-react-helmet="true" property="og:type" content="article" />
      )}
      {seo.title && (
        <meta
          data-react-helmet="true"
          property="og:title"
          content={seo.title}
        />
      )}
      {seo.description && (
        <meta
          data-react-helmet="true"
          property="og:description"
          content={seo.description}
        />
      )}
      {seo.image && (
        <meta
          data-react-helmet="true"
          property="og:image"
          content={seo.image}
        />
      )}
      {seo.image && seo.image.startsWith("https") && (
        <meta
          data-react-helmet="true"
          property="og:image:secure_url"
          content={seo.image}
        />
      )}
      <meta
        data-react-helmet="true"
        name="twitter:card"
        content="summary_large_image"
      />
      {twitterUsername && (
        <meta
          data-react-helmet="true"
          name="twitter:creator"
          content={twitterUsername}
        />
      )}
      {seo.title && (
        <meta
          data-react-helmet="true"
          name="twitter:title"
          content={seo.title}
        />
      )}
      {seo.description && (
        <meta
          data-react-helmet="true"
          name="twitter:description"
          content={seo.description}
        />
      )}
      {seo.image && (
        <meta
          data-react-helmet="true"
          name="twitter:image"
          content={seo.image}
        />
      )}
    </Helmet>
  )
}
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`
