import React from "react"
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"
import CatNews from "../components/catnews"
import { ArticleConsumer, PlayerContext } from "../components/player-context"

export default function CatNewsTemplate({ data }) {
  const catArabicName =
    data.nshra.topNews &&
    data.nshra.topNews.articles.length > 0 &&
    !useLocation().pathname.match(/(\/([a-z]{2})[\/]*)$/)
      ? data.nshra.topNews.articles[0].predicted_category
      : "الرئيسية"

  return (
    <ArticleConsumer>
      {playerContext => (
        <CatNews
          {...playerContext.setPlaylistTitle(
            catArabicName == "الرئيسية" ? "الكل" : catArabicName
          )}
          {...playerContext.setFuturePlaylist(data.nshra.topNews.articles)}
          items={data.nshra.topNews.articles}
          catArabicName={catArabicName}
          hidePlayButton={false}
        ></CatNews>
      )}
    </ArticleConsumer>
  )
}

export const query = graphql`
  query($cat: String!, $countryCode: String) {
    nshra {
      topNews(cat: $cat, countryCode: $countryCode) {
        articles {
          id
          image
          link
          predicted_category
          published
          rank
          source_category
          sourcename
          source_countryCode
          title
          sources_count
        }
      }
    }
  }
`
